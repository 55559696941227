import React from "react";
import { StyledMobileScroller } from "./style";

export default ({ children, space = 20, desktop = false }) => {
  return (
    <StyledMobileScroller space={space} desktop={desktop}>
      {children}
    </StyledMobileScroller>
  );
};
