import styled, { css } from "styled-components";
import {
  BP,
  Rem,
} from "../../commons/Theme";

export const StyledMobileScroller = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 0 ${Rem(24)};

  ${(props) => css`
    > * {
      margin-left: ${Rem(props.space)};

      @media (${BP.ipad}) {
        margin-left: 0;
        margin-right: ${props.desktop ? Rem(props.space) : 0};
      }

      &:last-child {
        &:after {
          position: relative;
          display: block;
          content: '';
          width: ${Rem(props.space)};
          height: 1px;
          left: 100%;
          margin-bottom: -1px;

          @media (${BP.ipad}) {
            display: none;
          }
        }
      }
    }
  `}

  ${(props) =>
    !props.desktop
    && css`
      @media (${BP.ipad}) {
        justify-content: space-between;
        overflow: hidden;
      }
    `}
`;
